/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from './../i18n';
import { withTranslation } from 'react-i18next';
import { history } from '../store';

// Actions
import { setCardType } from './actions/idPropertiesActions';

// Services
import documentService from '../services/shared/documentService';
import { isIdPalCameraEnabled } from '../services/shared/helpers';

// Components
import Header from './Header';
import Navigator from './Navigator';
import CustomButton from './Button';
import OutOfRecaptures from './Messages/OutOfRecaptures';
import StageComplete from './Messages/StageComplete';

// Image Components
import PassportSVG from './SVG/Passport';
import PassportTwoPageSVG from './SVG/PassportTwoPage';
import DriversLicenseFrontSVG from './SVG/DriversLicenseFront';
import DriversLicenseBackSVG from './SVG/DriversLicenseBack';
import NationalIdFrontSVG from './SVG/NationalIdFront';
import NationalIdBackSVG from './SVG/NationalIdBack';

// Config
import { imageAlt } from '../config/accessabilityRules';
import { ACTION_LABELS } from '../config/dataDogActionLabels';

// Constants
const ACTION = ACTION_LABELS.instructions;

class Instructions extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.paperPassportFocusRef = React.createRef();
        this.state = {
            isLandscape: false,
            navigation: {
                action: 'load',
                props: null,
            },
        };

        this.continue = this.continue.bind(this);
    }

    componentDidMount() {
        // Sets focus on screen transition
        if (this.showPaperLicence()) {
            // Sets to first link
            if (
                this.paperPassportFocusRef &&
                this.paperPassportFocusRef.current
            ) {
                this.paperPassportFocusRef.current.focus();
            }
        } else {
            // if no link, sets to h1 tag
            if (this.primaryFocusRef && this.primaryFocusRef.current) {
                this.primaryFocusRef.current.focus();
            }
        }

        // Sets instruction screen orientation based on if idpal capture is enabled
        this.setImageOrientation();

        // Sets document title
        const { t } = this.props;
        if (['31', '0'].includes(this.props.match.params.type)) {
            document.title = t('idpal_doc_title_instructions_driving');
        } else {
            document.title = t('idpal_doc_title_instructions_passport');
        }
    }

    getInstructions() {
        const { t } = this.props;

        // returns instructions messages based on if manual or auto capture is supported by the device
        if (
            this.props.match.params.type === '1' &&
            this.props.onePagePassport === 0
        ) {
            return t('idpal_two_page_passport_instructions');
        } else if (this.props.match.params.side === '1') {
            return this.props.autoIdCaptureSupported
                ? t('idpal_auto_id_capture_instructions')
                : t('idpal_front_instructions');
        } else {
            if (this.props.match.params.type === '31') {
                return this.props.autoIdCaptureSupported
                    ? t('idpal__auto_drivers_license_back_instructions')
                    : t('idpal_drivers_licence_back_instructions');
            } else {
                return this.props.autoIdCaptureSupported
                    ? t('idpal_auto_id_card_back_instructions')
                    : t('idpal_id_card_back_instructions');
            }
        }
    }

    isTwoPagePassport() {
        return (
            (this.props.match.params.type === '1' &&
                this.props.onePagePassport === 0) ||
            this.props.isExpiredTwoPagePassport
        );
    }

    getDriversLicensePadding() {
        const string = i18n.t('idpal_driving_licence');
        return 127 - (string.length - 15) * 4.75;
    }

    showPaperLicence() {
        // Show paper licence link if enabled and first side of DL instructions
        return (
            process.env.REACT_APP_PAPER_LICENCE_ENABLED === 'true' &&
            ['31', '0'].includes(this.props.match.params.type) &&
            this.props.match.params.side === '1'
        );
    }

    setImageOrientation() {
        if (this.props.idpalOverlaySupported) {
            this.setState({
                isLandscape: true,
            });
        }
    }

    continue(overrideDocument) {
        let documentId = this.props.location.state.documentId;

        // If we are continuing with a different kind of document than originally selected
        if (overrideDocument !== '') {
            const document = documentService.findByName(overrideDocument);
            this.props.setCardType(document.id);
            documentId = document.id;
        }

        this.setState({
            navigation: {
                action: 'next',
                props: {
                    documentId: documentId,
                    isRetry: this.props.location.state.isRetry,
                    isIdPalCamera:
                        isIdPalCameraEnabled(this.props.captureMethod) ||
                        this.props.bypassAcuant,
                },
            },
        });
    }

    render() {
        const { t } = this.props;

        // Document upload is already completed
        if (this.props.completed) {
            return (
                <Fragment>
                    <Header />
                    <StageComplete
                        continue={() =>
                            this.setState({ navigation: { action: 'next' } })
                        }
                        message='idpal_document_captured'
                    />
                    <Navigator
                        page={'document_review'}
                        action={this.state.navigation.action}
                        propsToPass={this.state.navigation.props}
                    />
                </Fragment>
            );
        }

        // Out of retries, and we've already tried this side
        if (
            this.props.submissionAttempts.remaining <= 0 &&
            this.props.submissionAttempts[
                this.props.sidesLeft === 2 ? 'front' : 'back'
            ] > 0
        ) {
            return (
                <Fragment>
                    <Header />
                    <OutOfRecaptures
                        continue={() => {
                            history.goForward();
                        }}
                        message='idpal_out_of_attempts'
                    />
                    <Navigator
                        page={'document_capture'}
                        action={this.state.navigation.action}
                        propsToPass={this.state.navigation.props}
                    />
                </Fragment>
            );
        }

        return (
            <Fragment>
                <Header />
                <div className='o-site-wrap instructions'>
                    <div className='instruction-img-wrap'>
                        <div
                            className={
                                this.isTwoPagePassport()
                                    ? 'svg-wrap--wide'
                                    : 'svg-wrap'
                            }
                        >
                            {/* Passport Images */}

                            {this.props.match.params.type === '1' && (
                                <Fragment>
                                    {/* Single Page Passport */}
                                    {this.props.onePagePassport === 1 && (
                                        <PassportSVG
                                            title={t(
                                                'idpal_passport'
                                            ).toUpperCase()}
                                            alt={imageAlt.passport}
                                            isLandscape={this.state.isLandscape}
                                        />
                                    )}

                                    {/* Two Page Passport */}
                                    {this.props.onePagePassport === 0 && (
                                        <PassportTwoPageSVG
                                            alt={imageAlt.passport}
                                        />
                                    )}
                                </Fragment>
                            )}

                            {['31', '0'].includes(
                                this.props.match.params.type
                            ) &&
                                this.props.match.params.side === '1' && (
                                    <DriversLicenseFrontSVG
                                        title={t(
                                            'idpal_driving_licence'
                                        ).toUpperCase()}
                                        padding={this.getDriversLicensePadding()}
                                        alt={imageAlt.driving}
                                        isLandscape={this.state.isLandscape}
                                    />
                                )}
                            {this.props.match.params.type === '31' &&
                                this.props.match.params.side === '2' && (
                                    <DriversLicenseBackSVG
                                        alt={imageAlt.drivingBack}
                                        isLandscape={this.state.isLandscape}
                                    />
                                )}
                            {this.props.match.params.type === '4' &&
                                this.props.match.params.side === '1' && (
                                    <NationalIdFrontSVG
                                        alt={imageAlt.nationalIdFront}
                                        isLandscape={this.state.isLandscape}
                                    />
                                )}
                            {this.props.match.params.type === '4' &&
                                this.props.match.params.side === '2' && (
                                    <NationalIdBackSVG
                                        alt={imageAlt.nationalIdBack}
                                        isLandscape={this.state.isLandscape}
                                    />
                                )}
                        </div>

                        {this.showPaperLicence() && (
                            <button
                                onClick={() => this.continue('Paper Licence')}
                                className={'small-text btn-link link-spacing'}
                                ref={this.paperPassportFocusRef}
                                data-dd-action-name={ACTION.paperLicenceButton}
                            >
                                {t('idpal_using_paper_licence')}
                            </button>
                        )}

                        <h1
                            className='u-text-center header'
                            ref={this.primaryFocusRef}
                            tabIndex={0}
                        >
                            {this.getInstructions()}
                        </h1>
                    </div>

                    <CustomButton
                        id='continue'
                        className='btn btn__arrow'
                        label={t('idpal_open_camera')}
                        handleClick={() => this.continue('')}
                        actionDataLabel={ACTION.openCameraButton}
                    />
                </div>

                <Navigator
                    page={'instructions'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        autoIdCaptureSupported: state.config.autoIdCaptureSupported,
        bypassAcuant: state.config.bypassAcuant,
        submissionAttempts: state.submissionState.submissionAttempts,
        completed:
            state.submissionState.submissionState.document_upload.completed,
        idpalOverlaySupported: state.config.idpalOverlaySupported,
        captureMethod:
            state.submissionState.screens.document_upload.capture_method,
        onePagePassport:
            state.submissionState.screens.document_upload.one_page_passport,
        isExpiredTwoPagePassport: state.idProperties.isExpiredTwoPagePassport,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCardType }, dispatch);
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(Instructions)
);
