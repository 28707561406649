/**
 * @prettier
 */

import selectStorage from './selectStorage';

export function stripSlashes(string) {
    return string.replace(new RegExp('\\\\', 'g'), '');
}

export function getCompanyName(name) {
    if (name !== '') {
        return stripSlashes(name);
    } else {
        return 'ID‑Pal';
    }
}

/**
 * Determines if we have camera permissions to prevent errors when embedded
 *
 * @return {Boolean}
 */
export async function checkCameraAccess() {
    if (window.self === window.top) {
        // Not in an iframe, not restricted
        return true;
    } else if (document.featurePolicy) {
        // Some browsers you can read the feature policy
        return document.featurePolicy.allowsFeature('camera');
    } else {
        // Otherwise, try to access camera
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
            });
            const tracks = stream.getTracks();
            tracks[0].stop();
            return true;
        } catch (err) {
            return false;
        }
    }
}

/**
 * Determines if loaded in an iframe
 *
 * @return {Boolean}
 */
export function isInIframe() {
    return window.self !== window.top;
}

/**
 * Check if we are on an iphone
 * Taken from AcuantCamera.js
 *
 * @return {Boolean}
 */
export function isIOS() {
    return (
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
}

/**
 * Checks if we should use Generic errors
 *
 * @return {Boolean}
 */
export function genericErrors() {
    return process.env.REACT_APP_GENERIC_ERRORS === 'true';
}

/**
 * Swaps elements in an array
 *
 * @param {Array} arr - Array to swap elements
 * @param {Number} i1 - First element to swap
 * @param {Number} i2  - Second element to swap with
 */
export function swapElementsOfArray(arr, i1, i2) {
    [arr[i1], arr[i2]] = [arr[i2], arr[i1]];
}

/**
 * Checks if browser cookies or storage are disabled
 *
 * @return {Boolean}
 */
export function areCookiesEnabled() {
    if (!navigator.cookieEnabled) {
        return false;
    }

    try {
        let storage = window.sessionStorage;
        storage.setItem('test', 'test');
        storage.removeItem('test');
        return true;
    } catch (e) {
        return false;
    }
}

/**
 * Checks if ID-Pal Capture is enabled
 *
 * @return {Boolean}
 */
export function isIdPalCameraEnabled(captureMethod) {
    return captureMethod === 'idpal';
}

/**
 * Calculates the size of a base64 image in bytes
 * @param {ImageData} image
 * @returns - image size in bytes
 */
export function calculateImageFileSize(image) {
    const src = image;
    const base64str = src.split('base64,')[1]; //remove the image type metadata.
    const imageFile = Buffer.from(base64str, 'base64'); //encode image into bytes
    return imageFile.length;
}

/**
 * Converts bytes to relative unit size
 * @param {Number} bytes
 * @returns object with image unit and file size
 */
export function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const fileSize = {};
    if (bytes === 0) {
        fileSize.unit = sizes[0];
        fileSize.size = parseInt(bytes, 10);
        return fileSize;
    }

    const sizeIndex = parseInt(
        Math.floor(Math.log(bytes) / Math.log(1024)),
        10
    );

    if (sizeIndex === 0) {
        fileSize.unit = sizes[sizeIndex];
        fileSize.size = parseFloat(bytes);
        return fileSize;
    }

    fileSize.unit = sizes[sizeIndex];
    fileSize.size = parseFloat((bytes / 1024 ** sizeIndex).toFixed(1));
    return fileSize;
}

/**
 * Show Reconnecting Message
 */
export function showReconnecting() {
    const toast = document.getElementById('reconnecting');
    toast.classList.add('show');
}

/**
 * Hide Reconnecting Message
 */
export function hideReconnecting() {
    const toast = document.getElementById('reconnecting');
    if (toast.classList.contains('show')) {
        toast.classList.add('hiding');
        toast.classList.remove('show');
        setTimeout(() => {
            toast.classList.remove('hiding');
        }, '500');
    }
}

/**
 * Converts language code to language name
 * @param {String} i18nLang
 * @returns language string
 */
export function getLanguage(i18nLang) {
    let language = '';

    if (i18nLang === 'es') {
        language = 'Español';
    } else if (i18nLang === 'fr') {
        language = 'Français';
    } else if (i18nLang === 'it') {
        language = 'Italiano';
    } else if (i18nLang === 'en-US') {
        language = 'US-English';
    } else {
        language = 'UK-English';
    }

    return language;
}

/**
 * Checks if the user has completed the submission.
 * @returns bool - isSubmissionComplete
 */
export function isSubmissionComplete() {
    return (
        selectStorage(areCookiesEnabled()).getItem('submission_complete') ===
        'true'
    );
}

/**
 * Updates language in URL param & session storage
 * @param {String} language
 */
export function updateUrlLanguageParam(language) {
    const newLangParam = `lang=${language}`;
    const urlParams = window.location.href.split('&');

    // Construct the new url
    const updatedUrl = urlParams
        .map(param => {
            if (param.includes('lang')) {
                param = newLangParam;
            }
            return param;
        })
        .join('&');

    // Replaces current url with updated URL
    window.history.replaceState({}, '', updatedUrl);
    // Updates the params without reloading the screen
    selectStorage(areCookiesEnabled()).setItem('lang', language);
}
