/**
 * @prettier
 */

export function submissionStateReducer(state = {}, action) {
    switch (action.type) {
        case 'SET_SUBMISSION_STATE':
            return {
                ...state,
                details: action.data.details,
                screens: action.data.screens,
                submissionState: action.data.state,
            };
        case 'SET_RETRY_LIMIT':
            return {
                ...state,
                submissionAttempts: {
                    remaining: action.data,
                    front: 0,
                    back: 0,
                },
            };
        case 'RECORD_SUBMISSION_ATTEMPT':
            let updatedAttempts = state.submissionAttempts;

            // Don't decrement for first submission of each side
            if (updatedAttempts[action.data] !== 0) {
                updatedAttempts.remaining = updatedAttempts.remaining - 1;
            }
            updatedAttempts[action.data] = updatedAttempts[action.data] + 1;
            return {
                ...state,
                submissionAttempts: updatedAttempts,
            };
        case 'SET_E_VERIFICATION_RESULT': {
            let updatedState = state.submissionState;
            updatedState.e_verification.result = action.data;
            updatedState.e_verification.completed = 1;
            return {
                ...state,
                submissionState: updatedState,
            };
        }
        case 'SET_STAGE_COMPLETED': {
            let updatedState = state.submissionState;
            updatedState[action.data].completed = 1;
            return {
                ...state,
                submissionState: updatedState,
            };
        }
        case 'SUBMISSION_STARTED':
            let updatedDetails = state.details;
            updatedDetails.new = 0;
            return {
                ...state,
                details: updatedDetails,
            };
        case 'CLEAR_SUBMISSION_STATE':
            return {
                ...state,
                details: {
                    new: 0,
                },
                screens: {},
                submissionState: {
                    document_upload: {
                        completed: 0,
                    },
                },
                submissionAttempts: {},
                isHandoff: false,
                errors: 0,
            };
        case 'IS_HANDOFF':
            return {
                ...state,
                isHandoff: action.data,
            };
        case 'COUNT_ERROR':
            return {
                ...state,
                errors: state.errors + 1,
            };
        default:
            return state;
    }
}
