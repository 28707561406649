/**
 * @prettier
 */

// React Packages
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { persistCombineReducers, persistStore } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './rootReducer';
import logService from './services/shared/logService';

const loggerMiddleWare = store => next => action => {
    logService.log('[LOG] Action triggered', action);
    next(action);
};

/**
 * cardType: 1 for ID/Passport, 2 for Medicard, 3 for National ID
 */

const initialState = {
    config: {
        instanceID: null,
        frontSubmitted: false,
        backSubmitted: false,
        showHeader: false,
        isRuuid: false,
        isBranding: true,
        isReCaptchaVerified: false,
        isUuidFromRuuidError: false,
        uuidValid: null,
        limitReached: false,
        acuantInitialized: false,
        bypassAcuant: false,
        autoLivenessSupported: false,
        autoIdCaptureSupported: false,
        idpalOverlaySupported: false,
        cropSettings: {},
        initializationParams: '',
        isDocSubmitted: false,
        isPoaDocSubmitted: false,
    },
    submissionState: {
        details: {
            new: 0,
        },
        screens: {},
        submissionState: {},
        submissionAttempts: {},
        isHandoff: false,
        errors: 0,
    },
    processedData: {
        faceMatch: null,
        result: null,
    },
    idProperties: {
        cardType: 0,
        orientation: 0,
        sidesLeft: 2,
        uncroppedImage: null,
        croppedImage: null,
        downscaledImage: null,
        isExpiredTwoPagePassport: false,
    },
    eVerification: {
        enabled: false,
        isAutoAddressFocus: false,
        isDateErrorFocus: false,
        customFields: [],
        countries: [],
        activatedCountries: [],
        selectedCountry: {
            name: '',
            iso_3166_3: '',
            iso_3166_2: '',
            supports_address_lookup: false,
        },
        initialCountry: null,
        firstName: null,
        lastName: null,
        dob: null,
        address1: '',
        address2: '',
        city: '',
        postCode: '',
        phone: null,
        phonePrefix: null,
        phoneRaw: null,
        customField: null,
        errors: {},
    },
    addressLookup: {
        hidden: false,
        fullResult: false,
        active: false,
    },
    spinner: {
        pendingItems: 0,
    },
};

function isFireFox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

if (isFireFox()) {
    (function (history) {
        history.pushState = function (state) {};
    })(window.history);
}

export const history = createBrowserHistory({
    basename: process.env.REACT_APP_BASENAME,
});

const config = {
    key: 'idscango',
    storage,
    blacklist: ['config', 'processedData', 'idProperties'],
};

const reducer = persistCombineReducers(config, rootReducer);

function configureStore() {
    let store = createStore(
        connectRouter(history)(reducer),
        initialState,
        applyMiddleware(thunk, loggerMiddleWare, routerMiddleware(history))
    );
    let persistor = persistStore(store);
    return { persistor, store };
}

export const { persistor, store } = configureStore();
