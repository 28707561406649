/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import i18n from './../i18n';

// Actions
import { setCardType } from './actions/idPropertiesActions';
import { setInstanceID } from './actions/configActions';

// Services
import { getLanguage, isIdPalCameraEnabled } from '../services/shared/helpers';

// Components
import Header from './Header';
import CustomButton from './Button';
import CustomSelectInput from './Inputs/CustomSelectInput';
import Navigator from './Navigator';

// Config
import { ACTION_LABELS } from '../config/dataDogActionLabels';
import LANGUAGES from '../config/languages';

// Constants
const ACTION = ACTION_LABELS.documentSelection;

class DocumentSelection extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            navigation: {
                action: 'load',
                props: {
                    instanceID: this.props.instanceID,
                },
            },
        };
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_select_doc');
    }

    // Set doc title when language changes
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { t } = this.props;
        document.title = t('idpal_doc_title_select_doc');
    }

    openCamera(type) {
        this.props.setCardType(type);
        this.navigateCamera(type);
    }

    navigateCamera(documentId) {
        this.setState({
            navigation: {
                action: 'next',
                props: {
                    documentId: documentId,
                    isIdPalCamera:
                        isIdPalCameraEnabled(this.props.captureMethod) ||
                        this.props.bypassAcuant,
                },
            },
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <Header />

                <div className='o-site-wrap'>
                    <div className='o-entryBtns'>
                        <h1
                            className='u-generic-text  u-text-center u-btm-buffer'
                            ref={this.primaryFocusRef}
                            tabIndex={0}
                        >
                            {t('xxx_entry_sentence')}
                        </h1>

                        {process.env.REACT_APP_ID_PASSPORT_ENABLED ===
                            'true' && (
                            <CustomButton
                                id='passport-button'
                                className='btn btn__arrow'
                                label={t('idpal_passport')}
                                handleClick={() => this.openCamera(1)}
                                actionDataLabel={ACTION.passportButton}
                            />
                        )}

                        {process.env.REACT_APP_ID_PASSPORT_ENABLED ===
                            'true' && (
                            <CustomButton
                                id='national-id-button'
                                className='btn btn__arrow'
                                label={t('idpal_national_id')}
                                handleClick={() => this.openCamera(3)}
                            />
                        )}

                        {process.env.REACT_APP_ID_PASSPORT_ENABLED ===
                            'true' && (
                            <CustomButton
                                id='driving-licence-button'
                                className='btn btn__arrow'
                                label={t('idpal_driving_licence')}
                                handleClick={() => this.openCamera(31)}
                                actionDataLabel={ACTION.drivingLicenceButton}
                            />
                        )}
                        {process.env.REACT_APP_ID_PASSPORT_ENABLED ===
                            'true' && (
                            <CustomButton
                                id='resident-card-button'
                                className='btn btn__arrow'
                                label={t('idpal_resident_card')}
                                handleClick={() => this.openCamera(32)}
                            />
                        )}
                        {process.env.REACT_APP_MEDICAL_CARD_ENABLED ===
                            'true' && (
                            <CustomButton
                                className='btn btn__arrow'
                                label='Capture Medical Card'
                                handleClick={() => this.openCamera(2)}
                            />
                        )}
                    </div>

                    {this.props.screens.start_screen && (
                        <div>
                            {!this.props.screens.start_screen.enabled && (
                                <div className='u-generic-text  u-text-center languageSelection'>
                                    <label>{t('idpal_language')}:</label>
                                    <CustomSelectInput
                                        defaultText={getLanguage(i18n.language)}
                                        optionsList={LANGUAGES}
                                        isLanguageChange={true}
                                        actionDataLabel={ACTION.languageSelect}
                                        labelHeader={t(
                                            'idpal_label_select_language'
                                        )}
                                        elementIdToFocus={i18n.language}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <Navigator
                    page={'document_selection'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        acuantInitialized: state.config.acuantInitialized,
        bypassAcuant: state.config.bypassAcuant,
        cardType: state.idProperties.cardType,
        branding: state.config.profile,
        uuidValid: state.config.uuidValid,
        limitReached: state.config.limitReached,
        pendingItems: state.spinner.pendingItems,
        screens: state.submissionState.screens,
        captureMethod:
            state.submissionState.screens.document_upload.capture_method,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCardType, setInstanceID }, dispatch);
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(DocumentSelection)
);
