/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { history } from '../store';

// Actions
import { setCaptured } from './actions/capturedActions';
import {
    setRetryLimit,
    submissionAttempt,
} from './actions/submissionStateActions';
import { setAutoIDCaptureError } from './actions/configActions';
import { setCapturedDocumentImage } from './actions/idPropertiesActions';

// Components
import Header from './Header';
import Navigator from './Navigator';
import CustomButton from './Button';
import StageComplete from './Messages/StageComplete';
import OutOfRecaptures from './Messages/OutOfRecaptures';
import InstructionModal from './Messages/InstructionModal';

// Images
import passportGif from '../assets/img/loading.gif';
import idGif from '../assets/gifs/loading_idcard.gif';
import loadingGif from '../assets/gifs/loading.gif';
import documentNotFound from '../assets/gifs/idpal_passport.gif';

// Services
import logService from '../services/shared/logService';
import DataDogService from '../services/shared/datadogService';

// Config
import { ACTION_LABELS } from '../config/dataDogActionLabels';
import { imageAlt } from '../config/accessabilityRules';

// Constants
const ACTION = ACTION_LABELS.acuantCamera;

// Global
let loadingTimer = null;
let isMounted = true;

class AcuantReactCamera extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.detectedCount = 0;
        this.state = {
            processing: false,
            cropMessage: false,
            receivedImage: false,
            previewImage: null,
            autoCaptureError: false,
            autoCaptureStarted: false,
            navigation: {
                action: 'load',
                props: null,
            },
        };
    }

    componentDidMount() {
        // set mounted on first render
        isMounted = true;

        if (
            this.props.submissionAttempts.remaining > 0 ||
            this.props.submissionAttempts[
                this.props.sidesLeft === 2 ? 'front' : 'back'
            ] === 0
        ) {
            this.clearLoading();
            this.startCamera();
        }

        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Set document title
        this.setDocumentTitle();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        this.setDocumentTitle();
    }

    componentWillUnmount() {
        // set mounted false on unmount
        isMounted = false;
        AcuantCameraUI.end();
    }

    setDocumentTitle() {
        const { t } = this.props;
        if (this.state.autoCaptureError) {
            document.title = t('idpal_doc_title_capture_id_error');
        } else if (this.props.completed) {
            document.title = t('idpal_doc_title_message_complete');
        } else if (this.checkIsOutOfRetries()) {
            document.title = t('idpal_doc_title_message_attempts');
        } else {
            document.title = t('idpal_doc_title_capture_id');
        }
    }

    // Out of retries, and we've already tried this side
    checkIsOutOfRetries() {
        return (
            this.props.submissionAttempts.remaining <= 0 &&
            this.props.submissionAttempts[
                this.props.sidesLeft === 2 ? 'front' : 'back'
            ] > 0
        );
    }

    // This function manipulates the DOM directly to cover gap between file selected and callback from SDK.
    // Otherwise, the "analyzing" gif will not show until onManualCapture is complete, which can take up to 3 Seconds.
    // If we have no file by 1 seconds of this being triggered, hide the dummy loader and prompt the user to retry.
    smoothLoading() {
        if (!loadingTimer) {
            let loader = document.getElementById('loader');
            let retry = document.getElementById('retry');
            if (loader && loader.classList) {
                loader.classList.remove('hidden');
            }
            if (retry && retry.classList) {
                retry.classList.add('hidden');
            }

            loadingTimer = setTimeout(() => {
                document.body.onfocus = null;
                let backButton = document.getElementById('back');
                if (backButton) {
                    backButton.click();
                }
            }, 1000);
        }
    }

    clearLoading() {
        document.body.onfocus = null;
        let loader = document.getElementById('loader');
        if (loader) {
            loader.classList.add('hidden');
        }
        clearTimeout(loadingTimer);
        loadingTimer = null;
    }

    // Only set processing if component is mounted
    setProcessing(value) {
        isMounted && this.setState({ processing: value });
    }

    onCaptured(response) {
        DataDogService.log('Image Captured Successfully');
        this.clearLoading();
        isMounted && this.setState({ receivedImage: true });
        this.setProcessing(true);
    }

    onCropped(response) {
        this.setProcessing(false);

        if (response) {
            this.props.setCaptured(response);
            let imageType = this.getImageType();

            let properties = {
                imageSize: response.image.size,
                width: response.image.width,
                height: response.image.height,
                originalWidth: response.image.originalWidth,
                originalHeight: response.image.originalHeight,
                properties: {
                    sharpness: response.sharpness,
                    glare: response.glare,
                    dpi: response.dpi,
                    documentId: this.props.location.state.documentId,
                    isPassport: response.isPassport,
                },
            };

            // Pass minimum amount of Images
            if (imageType === 'cropped' && response.cropped) {
                this.props.setCapturedDocumentImage(
                    response.image.data,
                    'cropped'
                );
            } else if (imageType === 'uncropped' || !response.cropped) {
                this.props.setCapturedDocumentImage(
                    response.image.uncroppedData,
                    'uncropped'
                );
                this.props.setCapturedDocumentImage(
                    response.image.downscaledData,
                    'downscaled'
                );
            } else {
                this.props.setCapturedDocumentImage(
                    response.image.data,
                    'cropped'
                );
                this.props.setCapturedDocumentImage(
                    response.image.uncroppedData,
                    'uncropped'
                );
                this.props.setCapturedDocumentImage(
                    response.image.downscaledData,
                    'downscaled'
                );
            }

            // Only log error if uncropped and not a paper licence
            if (
                !response.cropped &&
                this.props.location.state.documentId !== 0
            ) {
                DataDogService.createError('Could not crop image.');
            }

            if (response.cropped) {
                DataDogService.log('Image Cropped Successfully');
            }

            // Show error modal on cropping errors until the user is on their last attempt
            // To prevent the preview screen from showing twice
            if (
                !response.cropped &&
                this.props.submissionAttempts.remaining > 1 &&
                this.props.location.state.documentId !== 0
            ) {
                isMounted &&
                    this.setState({
                        previewImage: response.image.uncroppedData,
                    });
                isMounted && this.setState({ cropMessage: true });
            }

            // Only proceed if we have a cropped image,
            // On last submission attempt, or is a paper licence
            if (
                response.cropped ||
                this.props.submissionAttempts.remaining <= 1 ||
                this.props.location.state.documentId === 0
            ) {
                isMounted &&
                    this.setState({
                        navigation: {
                            action: 'next',
                            props: properties,
                        },
                    });
            }
        }
    }

    onFrameAvailable(response) {}

    startCamera() {
        isMounted && this.setState({ autoCaptureError: false });

        if (AcuantCameraUI) {
            document.body.onfocus = this.smoothLoading;

            // Auto capture not enabled, or paper licence manual capture.
            if (
                !this.props.autoIdCaptureSupported ||
                this.props.location.state.documentId === 0
            ) {
                this.manualCapture();
            } else if (
                this.props.autoIdCaptureSupported &&
                AcuantCamera.isCameraSupported
            ) {
                // Perform auto capture if the device supports it
                this.autoCapture();
            } else {
                // fallback to manual capture if there are any uncaught errors
                !this.state.autoCaptureError && this.manualCapture();
            }
        }
    }

    autoCapture() {
        const { t } = this.props;
        isMounted && this.setState({ autoCaptureStarted: true });
        const options = {
            text: {
                NONE: t('idpal_align'),
                SMALL_DOCUMENT: t('idpal_closer'),
                GOOD_DOCUMENT: null, //null countdown
                BIG_DOCUMENT: t('idpal_id_too_close'),
                CAPTURING: t('idpal_capturing'),
                TAP_TO_CAPTURE: t('idpal_tap_to_capture'),
            },
        };

        try {
            AcuantCameraUI.start(
                {
                    onCaptured: this.onCaptured.bind(this),
                    onCropped: this.onCropped.bind(this),
                    onFrameAvailable: this.onFrameAvailable.bind(this),
                },
                this.onError.bind(this),
                options
            );
        } catch (error) {
            DataDogService.error(error);
        }
    }

    manualCapture() {
        let imageType = this.getImageType();

        try {
            AcuantCamera.startManualCapture(
                {
                    onCaptured: this.onCaptured.bind(this),
                    onCropped: this.onCropped.bind(this),
                },
                imageType,
                this.onError.bind(this)
            );
        } catch (error) {
            DataDogService.error(error);
        }
    }

    getImageType() {
        const passportCropSettings = this.props.cropSettings.passport;
        const idCardCropSettings = this.props.cropSettings.id_card;

        if (
            passportCropSettings !== idCardCropSettings ||
            (passportCropSettings === 0 && idCardCropSettings === 0)
        ) {
            return 'both';
        } else if (this.props.cropSettings.passport === 1) {
            return 'cropped';
        } else {
            return 'uncropped';
        }
    }

    onError(error) {
        // If there is an error loading acuant auto capture
        // sets the error message to start manual fallback
        AcuantCamera.isCameraSupported = false;
        this.clearLoading();
        this.props.setAutoIDCaptureError();
        isMounted &&
            this.setState({
                autoCaptureError: true,
            });
        logService.error(error);
    }

    handleCancel() {
        if (!this.state.receivedImage) {
            this.goBack();
        }
    }

    goBack() {
        isMounted &&
            this.setState({
                navigation: {
                    action: 'back',
                    props: {
                        documentId: this.props.cardType,
                        sidesLeft: this.props.sidesLeft,
                    },
                },
            });
    }

    retryPhoto() {
        this.clearLoading();
        isMounted &&
            this.setState({
                receivedImage: false,
                cropMessage: false,
                previewImage: null,
                processing: false,
                autoCaptureError: false,
            });
        // Decrement submission attempts before classification
        this.props.submissionAttempt('front');
        this.startCamera();
    }

    render() {
        const { t } = this.props;
        let analyzingGif = this.props.cardType === 1 ? passportGif : idGif;

        // Document upload is already completed
        if (this.props.completed) {
            return (
                <Fragment>
                    <Header />
                    <StageComplete
                        continue={() =>
                            this.setState({ navigation: { action: 'next' } })
                        }
                        message='idpal_document_captured'
                    />
                    <Navigator
                        page={'document_review'}
                        action={this.state.navigation.action}
                        propsToPass={this.state.navigation.props}
                    />
                </Fragment>
            );
        }

        // Out of retries, and we've already tried this side
        if (
            this.props.submissionAttempts.remaining <= 0 &&
            this.props.submissionAttempts[
                this.props.sidesLeft === 2 ? 'front' : 'back'
            ] > 0
        ) {
            return (
                <Fragment>
                    <Header />
                    <OutOfRecaptures
                        continue={() => {
                            history.goForward();
                        }}
                        message='idpal_out_of_attempts'
                    />
                    <Navigator
                        page={'document_capture'}
                        action={this.state.navigation.action}
                        propsToPass={this.state.navigation.props}
                    />
                </Fragment>
            );
        }

        return (
            <Fragment>
                {/* Modal popup instructions  */}
                {this.state.cropMessage &&
                    this.props.submissionAttempts.remaining >= 0 && (
                        <InstructionModal
                            heading={t('idpal_no_image_detected')}
                            message={t('idpal_no_image_user_message')}
                            image={documentNotFound}
                            showCta={true}
                        />
                    )}

                <Header />
                <div id='acuant-camera'></div>

                <div
                    id={'loader'}
                    className='u-text-center start-loading hidden'
                >
                    <img
                        alt={imageAlt.loading}
                        src={loadingGif}
                        className='capture'
                    />
                </div>

                {!this.state.processing && (
                    <div id={'loading'} className='o-site-wrap hidden'>
                        <div className='u-display-analysing u-text-center hidden'>
                            <CustomButton
                                id={'back'}
                                className={'btn hidden'}
                                handleClick={() => this.handleCancel()}
                                actionDataLabel={ACTION.backHiddenButton}
                            />
                        </div>
                    </div>
                )}

                {this.state.processing && (
                    <div
                        className={
                            this.state.processing
                                ? 'o-site-wrap'
                                : 'o-site-wrap'
                        }
                    >
                        <h1
                            className='u-generic-text u-text-center u-btm-buffer loading-ellipse'
                            ref={this.primaryFocusRef}
                            tabIndex={0}
                        >
                            {t('idpal_uploading')}
                            <span className='dot1'>.</span>
                            <span className='dot2'>.</span>
                            <span className='dot3'>.</span>
                        </h1>
                        <div className='u-display-analysing u-text-center'>
                            <img
                                alt={imageAlt.idCardLoading}
                                src={analyzingGif}
                                className='capture'
                            />
                        </div>
                    </div>
                )}

                {/* Show preview of badly cropped image */}
                {this.state.cropMessage &&
                    this.props.submissionAttempts.remaining >= 0 && (
                        <div className='o-site-wrap'>
                            <div className='u-generic-text u-text-center u-btm-buffer'>
                                {t('idpal_classification_error')}
                            </div>

                            <div className={'u-display-upload u-text-center'}>
                                {this.state.previewImage && (
                                    <img
                                        id='document-image'
                                        alt={imageAlt.upLoadedDocument}
                                        src={this.state.previewImage}
                                        className={'capture'}
                                    />
                                )}
                            </div>

                            <div className='m-center'>
                                <div className='u-generic-text  u-text-center'>
                                    <CustomButton
                                        id={'retry'}
                                        className={'btn'}
                                        label={t('idpal_retry')}
                                        handleClick={() => this.retryPhoto()}
                                        actionDataLabel={ACTION.retryButton}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                {!this.state.processing &&
                    this.props.submissionAttempts.remaining >= 0 &&
                    !this.state.cropMessage && (
                        <>
                            {this.state.autoCaptureError ? (
                                <div className='o-site-wrap instructions'>
                                    <div
                                        id='error'
                                        className='u-generic-text u-text-center u-md-btm-buffer error-buffer'
                                    >
                                        {t(
                                            'idpal_auto_id_capture_error_message'
                                        )}
                                    </div>
                                    <div className='u-generic-text u-text-center'>
                                        <CustomButton
                                            id={'capture'}
                                            className={'btn'}
                                            label={t('idpal_manual_capture')}
                                            handleClick={() =>
                                                this.startCamera()
                                            }
                                            actionDataLabel={
                                                ACTION.captureButton
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={
                                        this.state.autoCaptureStarted
                                            ? 'hidden'
                                            : 'capture-upload-page u-flex u-flex--row u-flex--vh u-flex--center p-success'
                                    }
                                >
                                    <div className='m-center'>
                                        <div
                                            id='retry'
                                            className='u-generic-text  u-text-center'
                                        >
                                            <CustomButton
                                                id={'retry'}
                                                className={'btn'}
                                                label={t('idpal_retry')}
                                                handleClick={() =>
                                                    this.retryPhoto()
                                                }
                                                actionDataLabel={
                                                    ACTION.retryButton
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                <video
                    id='acuant-player'
                    controls
                    autoPlay
                    playsInline
                    style={{ display: 'none' }}
                ></video>
                <div style={{ textAlign: 'center' }}>
                    <canvas
                        id='acuant-video-canvas'
                        width='100%'
                        height='auto'
                    ></canvas>
                </div>

                <Navigator
                    page={'document_capture'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        cardType: state.idProperties.cardType,
        submissionAttempts: state.submissionState.submissionAttempts,
        completed:
            state.submissionState.submissionState.document_upload.completed,
        cropSettings: state.config.cropSettings,
        sidesLeft: state.idProperties.sidesLeft,
        autoIdCaptureSupported: state.config.autoIdCaptureSupported,
        autoIdCaptureError: state.config.autoIdCaptureError,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setCaptured,
            setRetryLimit,
            submissionAttempt,
            setAutoIDCaptureError,
            setCapturedDocumentImage,
        },
        dispatch
    );
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(AcuantReactCamera)
);
